import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Generator from './Generator'

const nodeVersions = ["1.3", "1.4", "1.5", "1.6"]

const Compose = () => {
    const [nodes, setNodes] = useState(3)
    const [additionalKeys, setAdditionalKeys] = useState(0)
    const [chainId, setChainId] = useState("Z")
    const [nodeVersion, setNodeVersion] = useState("1.3")

    return (<Grid container spacing={3}>
        <Grid item xs={6}>
            <Box my={6}>
                <Typography variant="h6">
                    Generate Docker-Compose
            </Typography>
                <div>
                    <TextField
                        label="Number of nodes (0-9)"
                        type="number"
                        defaultValue={nodes}
                        onChange={(e) => setNodes(Number.parseInt(e.target.value))}
                        placeholder="3"
                        fullWidth />
                </div>
                <div>
                    <TextField
                        label="Number of additional keys (0-9)"
                        type="number"
                        defaultValue={additionalKeys}
                        onChange={(e) => setAdditionalKeys(Number.parseInt(e.target.value))}
                        placeholder="0"
                        fullWidth />
                </div>
                <div>
                    <TextField
                        label="Network byte (A-Z)"
                        defaultValue={chainId}
                        onChange={(e) => setChainId(e.target.value)}
                        placeholder="Z"
                        fullWidth />
                </div>
                <div>
                <InputLabel shrink>
                    Node Version
                </InputLabel>
                <Select
                    label="Node Version"
                    value={nodeVersion}
                    onChange={(e) => setNodeVersion(`${e.target.value}`)}
                    placeholder="1.3"
                    fullWidth
                >
                    {nodeVersions.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                    </Select>
                </div>
            </Box>
            <Generator 
                fetchUrl={`initializr/generator/compose/local?nodes=${nodes}&additionalKeys=${additionalKeys}&chainId=${chainId}&nodeVersion=${nodeVersion}`} 
                archiveName="docker-compose"/>
        </Grid>
    </Grid>)
}

export default Compose