import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Generator from './Generator'

const nodeVersions = ["1.3", "1.4", "1.5", "1.6"]

interface Org {
    nodes: number;
    additionalKeys: number;
    roles: string[]
}

const Network = () => {
    const [orgs, setOrgs] = useState<Org[]>([])
    const [chainId, setChainId] = useState("Z")
    const [nodeVersion, setNodeVersion] = useState("1.3")
    const [rolesList, setRolesList] = useState<string[]>([])

    useEffect(() => {
        const fetchDependencies = async () => {
            const result = await fetch(`initializr/dictionary/roles`).then(rs => rs.json())
            setRolesList(result)
        }
        fetchDependencies()
    }, [])

    const addOrg = () => {
        setOrgs([...orgs, {
            nodes: 3,
            additionalKeys: 0,
            roles: rolesList
        }])
    }

    const delOrg = (index: number) => {
        setOrgs([...orgs.slice(0, index), ...orgs.slice(index + 1)])
    }

    const setNodes = (index: number, nodes: number) => {
        const copy = [...orgs]
        copy[index] = {
            ...orgs[index],
            nodes
        }
        setOrgs(copy)
    }

    const setAdditionalKeys = (index: number, additionalKeys: number) => {
        const copy = [...orgs]
        copy[index] = {
            ...orgs[index],
            additionalKeys
        }
        setOrgs(copy)
    }

    const setRoles = (index: number, roles: string[]) => {
        const copy = [...orgs]
        copy[index] = {
            ...orgs[index],
            roles
        }
        setOrgs(copy)
    }

    return (<Grid container spacing={3}>
        <Grid item xs={6}>
            <Box my={6}>
                <Typography variant="h6">
                    Generate Network
                </Typography>

                {
                    orgs.map((org, index) => <div key={index}>
                        <Typography variant="h6">
                            Org {index + 1} <Button color="secondary" onClick={() => delOrg(index)}>Delete</Button>
                        </Typography>
                        <div>
                            <TextField
                                label="Number of nodes (0-9)"
                                type="number"
                                defaultValue={org.nodes}
                                onChange={(e) => setNodes(index, Number.parseInt(e.target.value))}
                                placeholder="3"
                                fullWidth />
                        </div>
                        <div>
                            <TextField
                                label="Number of additional keys (0-9)"
                                type="number"
                                defaultValue={org.additionalKeys}
                                onChange={(e) => setAdditionalKeys(index, Number.parseInt(e.target.value))}
                                placeholder="0"
                                fullWidth />

                            <Select
                                multiple
                                value={org.roles}
                                onChange={(e) => setRoles(index, e.target.value as string[])}
                                input={<Input />}
                                renderValue={(selected) => (selected as string[]).join(', ')}
                            >
                                {rolesList.map((roles) => (
                                    <MenuItem key={roles} value={roles}>
                                        <Checkbox checked={org.roles.indexOf(roles) > -1} />
                                        <ListItemText primary={roles} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>)
                }

                <Button onClick={addOrg}>
                    Add Org
                </Button>

                <div>
                    <TextField
                        label="Network byte (A-Z)"
                        defaultValue={chainId}
                        onChange={(e) => setChainId(e.target.value)}
                        placeholder="Z"
                        fullWidth />
                </div>
                <div>
                    <InputLabel shrink>
                        Node Version
                    </InputLabel>
                    <Select
                        label="Node Version"
                        value={nodeVersion}
                        onChange={(e) => setNodeVersion(`${e.target.value}`)}
                        placeholder="1.3"
                        fullWidth
                    >
                        {nodeVersions.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                    </Select>
                </div>
            </Box>
            <Generator
                fetchUrl={`initializr/generator/network/local?chainId=${chainId}&nodeVersion=${nodeVersion}`}
                body={JSON.stringify(orgs)}
                archiveName="network" />
        </Grid>
    </Grid>)
}

export default Network
