import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Generator from './Generator'

export interface DAppProps {
    snapshot: boolean;
}

interface Dependency {
    groupId: string;
    artifactId: string;
    version: string;
    required: boolean;
    description: string;
}

const DApp = (props: DAppProps) => {
    const { snapshot } = props
    const [code, setCode] = useState("")
    const [repository, setRepository] = useState("")
    const [dependencies, setDepdencies] = useState<Dependency[]>([])
    useEffect(() => {
        const fetchDependencies = async () => {
            const result = await fetch(`initializr/artifacts/set?name=dapp&snapshot=${snapshot}`).then(rs => rs.json())
            setDepdencies(result)
        }
        fetchDependencies()
    }, [snapshot])

    return (<Grid container spacing={3}>
        <Grid item xs={6}>
            <Box my={6}>
                <Typography variant="h6">
                    Infrastructure
            </Typography>
                <div>
                    <TextField
                        label="Docker registry"
                        placeholder="registry.wavesenterprise.com"
                        value={repository}
                        onChange={e => setRepository(e.target.value)}
                        fullWidth />
                </div>
                <div>
                    <TextField
                        label="Nexus registry"
                        placeholder="artifacts.wavesenterprise.com"
                        fullWidth />
                </div>
            </Box>
            <Box my={6}>
                <Typography variant="h6">
                    Project
        </Typography>
                <div>
                    <TextField
                        label="Code"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        placeholder=""
                        fullWidth />
                </div>
            </Box>
            <Box my={6}>
                <Typography variant="h6">
                    Contract
                </Typography>
                <div>
                    <TextField
                        label="Name"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        placeholder=""
                        fullWidth />
                </div>
            </Box>
            <Box my={6}>
                <Typography variant="h6">
                    dApp
                </Typography>
                <div>
                    <TextField
                        label="Name"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        placeholder=""
                        fullWidth />
                </div>
            </Box>
            <Generator
                fetchUrl={`initializr/generator/dapp/local?code=${code}&image=image&repository=${repository}&snapshot=${snapshot}`}
                archiveName={`${code}-app`} />
        </Grid>
        <Grid item xs={6}>
            <Box my={6}>
                <Typography variant="h6" style={{marginBottom: "10px"}}>Dependencies</Typography>
                <div>
                    {
                        dependencies.map(dep =>
                            <Card style={{marginBottom: "10px"}} variant="outlined" key={`${dep.groupId}:${dep.artifactId}`}>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        {dep.version || "No version"}
                                    </Typography>
                                    <Typography variant="h6" component="h3" gutterBottom>
                                        {`${dep.groupId}:${dep.artifactId}`}
                                    </Typography>
                                    <Typography color="secondary" variant="h6" component="h6">
                                        {dep.description}
                                    </Typography>
                                </CardContent>
                            </Card>)
                    }
                </div>
            </Box>
        </Grid>
    </Grid>)
}

export default DApp