import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import downloadjs from 'downloadjs';

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }))

export interface GeneratorProps {
    fetchUrl: string;
    archiveName: string;
    body?: string;
}

const Generator = (props: GeneratorProps) => {
    const { fetchUrl, archiveName, body } = props
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState("")

    const generate = async () => {
        setLoading(true)
        const task = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body
        }).then(rs => rs.json())

        if (task.error) {
            setLoading(false)
            setStep(`${task.error} ${task.message}`)
        } else {
            const poller = setInterval(async () => {
                const status = await fetch(`/initializr/task/${task.id}/status`).then(rs => rs.json())
                if (status.status === 'COMPLETED') {
                    clearInterval(poller)
                    const buffer = await fetch(`/initializr/task/${task.id}/result`).then(rs => rs.arrayBuffer())
                    setLoading(false)
                    setStep("")
                    downloadjs(new Uint8Array(buffer), `${archiveName}.zip`, 'application/zip')
                } else if (status.status === 'ERROR') {
                    clearInterval(poller)
                    setStep(`Error executing: ${status.step}`)
                    setLoading(false)
                } else {
                    setStep(status.step)
                }
            }, 1000)
        }
    }

    return (
    <Box my={6}>
        <Button onClick={generate} disabled={loading} variant="contained" color="primary">{loading && <CircularProgress size={24} className={classes.buttonProgress} />}Generate</Button>
        <span>
            {step}
        </span>
    </Box>)
}

export default Generator