import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Switch2 from '@material-ui/core/Switch';
import Compose from './components/Compose'
import Network from './components/Network'
import DApp from './components/DApp';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
}));

export default function App() {
  const classes = useStyles();
  const [snapshot, setSnapshot] = useState(false)
  const [sdkVersion, setSdkVersion] = useState(null)

  const tabs = [
    {
      "id": "1",
      "element": <Compose />,
      "enabled": true,
      "name": "Local Docker compose"
    },
    {
      "id": "2",
      "element":  <Network />,
      "enabled": true,
      "name": "Network topology"
    },
    {
      "id": "3",
      "element": <DApp snapshot={snapshot}/>,
      "enabled": true,
      "name": "Decentralized application"
    }
  ]
  const [activeTab, setActiveTab] = useState(tabs[0].id)
  
  useEffect(() => {
    const fetchSdkVersion = async() => {
      const version = await fetch(`/initializr/artifacts/sdk/version?snapshot=${snapshot}`)
                .then(rs => rs.json())
      setSdkVersion(version.version||'NO_VERSION')
    }
    fetchSdkVersion()
  }, [snapshot])

  return (
    <Container maxWidth="xl">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Web3Tech Initializr
            </Typography>
          <FormControlLabel
            control={<Switch2 value={snapshot} onClick={() => setSnapshot(!snapshot)} />}
            label="Use SNAPSHOT"
          />
          <Typography variant="h6">
            SDK Version: {sdkVersion}
            </Typography>
        </Toolbar>
      </AppBar>
      <Tabs value={activeTab} aria-label="simple tabs example" indicatorColor="primary"
        textColor="primary"
        centered>
          {
            tabs.map(tab => <Tab
              id={tab.id}
              key={tab.id}
              value={tab.id}
              label={tab.name}
              disabled={!tab.enabled}
              onClick={() => setActiveTab(tab.id)}
              />)
          }
      </Tabs>
      {tabs.filter(tab => tab.id === activeTab)[0].element}
    </Container>
  );
}
